import { Package } from 'types/catalogue.v2'

export const packageContainsLGSR = (packageObj: Package | undefined): boolean => {
    if (!packageObj) {
        return false
    }

    return packageObj.products.some(
        (product) => product.name === 'Landlord Gas Safety Record (LGSR)'
    )
}
