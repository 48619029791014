import Cookies from 'js-cookie'
import { CookieDataType } from 'types/formData'
import { getCookieDomain } from 'utils/getCookieDomain'
import { CTM_ESSENTIALS_PACKAGES, INSURANCE_ESSENTIALS_PACKAGES } from 'utils/packages'

export const urlToDataObj = (urlSearch: string, existingCookieData: any) => {
    const params = new URLSearchParams(urlSearch)
    const dataObj: CookieDataType = {}

    dataObj.pricebookId = params.get('pricebook') || existingCookieData.pricebookId || ''
    dataObj.packageId = params.get('product') || existingCookieData.packageId || ''
    dataObj.email_address = params.get('email_address') || existingCookieData.email_address
    dataObj.postcode = params.get('postcode') || existingCookieData.postcode
    dataObj.billing = params.get('billing') || existingCookieData.billing || ''
    dataObj.referer = params.get('referer') || existingCookieData.referer || ''
    dataObj.qs = params.get('qs') || existingCookieData.qs || ''
    dataObj.source = params.get('utm_source') || existingCookieData.source || ''
    dataObj.salesAgent = params.get('sales-agent') || existingCookieData.salesAgent || ''
    dataObj.landing = params.get('landing') || existingCookieData.landing || ''
    dataObj.gaclientid = params.get('gaclientid') || existingCookieData.gaclientid || ''
    dataObj.transactionId =
        existingCookieData?.transactionId || `${new Date().getTime().toString()}000`

    dataObj.addons = [
        {
            name: '3 Year Fixed Price',
            selected: params.get('3-year-fixed') === 'true',
            id: null,
            prices: null,
        },
    ]

    // ensure contribution is 95 for all essentials packages
    dataObj.contribution =
        dataObj.packageId &&
        [...CTM_ESSENTIALS_PACKAGES, ...INSURANCE_ESSENTIALS_PACKAGES].includes(+dataObj.packageId)
            ? '95'
            : params.get('contribution') || existingCookieData.contribution || ''

    const referralCode = params
        .get('qs')
        ?.split('&')
        .find((ref) => ref.includes('REF'))

    if (referralCode && typeof referralCode === 'string') {
        dataObj.referralCodeURL = referralCode.replace('?q=', '')
    }

    return dataObj
}

export const initialiseCookie = (cookieName: string): void => {
    const existingCookieData = JSON.parse(Cookies.get(cookieName) || '{}')

    const cookieData = urlToDataObj(window.location.search, existingCookieData)
    Cookies.set(cookieName, JSON.stringify({ ...existingCookieData, ...cookieData }), {
        domain: getCookieDomain(),
    })
}
