import { PromoCode } from './promocode'
import { Addon } from '../contexts/checkout/type'

export enum BillingType {
    MONTHLY = 'monthly',
    ANNUAL = 'annual',
}
export interface Correspondence {
    address_line_1?: string
    postcode?: string
    town?: string
    country?: string
}

export interface CookieDataType {
    forename?: string
    surname?: string
    email_address?: string
    contact_tel?: string
    street?: string
    town?: string
    county?: string
    postcode?: string
    date?: string
    source?: string
    referer?: string
    qs?: string
    landing?: string
    gaclientid?: string
    quote_id?: string
    contribution?: string
    billing?: string
    pricebookId?: string
    packageId?: string
    packageType?: string
    addons?: Addon[]
    productName?: string
    promoCode?: {
        code?: string
        type?: PromoCode
        value?: number
    }
    monthlyTotalPrice?: number
    monthlyDiscount?: number
    transactionId?: string
    nextServiceDate?: string
    goCardless?: {
        // TODO keeping as optional as we're A/B testing with the old checkout
        billing_request_id: string
        billing_request_flow_id: string
        creditor_id: string
        customer_id: string
        customer_bank_account_id: string
        customer_billing_detail_id: string
        mandate_request_id: string
        mandate_request_mandate_id: string
    }
    isInsurancePackage?: boolean
    referralLink?: string
    referralCode?: string
    referralProgramme?: boolean
    marketing?: boolean
    marketingEmail?: boolean
    marketingPhone?: boolean
    marketingSms?: boolean
    salesAgent?: string
    registerCustomerRequest?: {
        isRequesting: boolean
        isError?: boolean
        key?: string
    }
    referralCodeURL?: string
}

export interface LeadDataType {
    expired: boolean
    lead: {
        id: number
        date_created: Date
        date_modified: Date
        transaction_id: string
        first_name?: string | null
        last_name?: string | null
        email?: string | null
        pricebook_id?: number | null
        package_id?: number | null
        promocode?: string | null
        package_name?: string | null
        street?: string | null
        town?: string | null
        county?: string | null
        postcode?: string | null
        lastservice?: string | null
        billing_type?: string | null
        contribution?: number | null
        gas_fires?: number | null
        marketing?: 0 | 1 | null
        monthly_total_price?: number | null
        package_type?: string | null
        phone_prefix?: string | null
        phone?: string | null
        referral_programme?: 0 | 1 | null
        querystring?: string | null
        landing_page?: string | null
        referer?: string | null
        in_dotmailer?: 0 | 1 | null
        in_hubspot?: 0 | 1 | null
        why_choose_us?: string | null
        new_product_suggestions?: string | null
        marketing_email?: 0 | 1 | null
        marketing_phone?: 0 | 1 | null
        marketing_sms?: 0 | 1 | null
        sales_agent?: string | null
        source?: string | null
    }
}
