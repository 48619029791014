import Ajv, { JSONSchemaType } from 'ajv'
import axios from 'axios'
import { CONTRACTS_ENDPOINT } from 'config/constants'
import { LeadDataType } from 'types/formData'
import { CheckoutState } from '../contexts/checkout/type'

const API_BASE_URL = process.env.REACT_APP_PORTAL_API_BASE_URL
const PANDORA_API_BASE_URL = process.env.REACT_APP_PANDORA_API_URL
interface CreateLeadInput {
    transactionId: string
    billingType?: string
    contribution?: string
    country?: string
    marketingEmail?: boolean
    marketingPhone?: boolean
    marketingSms?: boolean
    monthlyTotalPrice?: number
    packageType?: string
    phonePrefix?: string
    referralProgramme?: boolean
    pricebookId?: number
    packageId?: number
    promoCode?: object
    packageName?: string
    street?: string
    town?: string
    county?: string
    postcode?: string
    lastService?: string
    firstName?: string
    lastName?: string
    phone?: string
    email?: string
    querystring?: string
    landingPage?: string
    httpReferrer?: string
    whyChooseUs?: string[]
    newProductsSuggestions?: string[]
}

const createLeadSchema: JSONSchemaType<CreateLeadInput> = {
    type: 'object',
    properties: {
        transactionId: { type: 'string' },
        billingType: { type: 'string', nullable: true },
        contribution: { type: 'string', nullable: true },
        country: { type: 'string', nullable: true },
        marketingEmail: { type: 'boolean', nullable: true },
        marketingPhone: { type: 'boolean', nullable: true },
        marketingSms: { type: 'boolean', nullable: true },
        monthlyTotalPrice: { type: 'number', nullable: true },
        packageType: { type: 'string', nullable: true },
        phonePrefix: { type: 'string', nullable: true },
        referralProgramme: { type: 'boolean', nullable: true },
        pricebookId: { type: 'number', nullable: true },
        packageId: { type: 'number', nullable: true },
        promoCode: { type: 'object', nullable: true },
        packageName: { type: 'string', nullable: true },
        street: { type: 'string', nullable: true },
        town: { type: 'string', nullable: true },
        county: { type: 'string', nullable: true },
        postcode: { type: 'string', nullable: true },
        lastService: { type: 'string', nullable: true },
        firstName: { type: 'string', nullable: true },
        lastName: { type: 'string', nullable: true },
        phone: { type: 'string', nullable: true },
        email: { type: 'string', nullable: true },
        querystring: { type: 'string', nullable: true },
        landingPage: { type: 'string', nullable: true },
        httpReferrer: { type: 'string', nullable: true },
        whyChooseUs: { type: 'array', items: { type: 'string' }, nullable: true },
        newProductsSuggestions: { type: 'array', items: { type: 'string' }, nullable: true },
    },
    required: ['transactionId'],
}

export const isValidLead = (state: CheckoutState) => {
    const ajv = new Ajv({ coerceTypes: true })
    const validator = ajv.compile(createLeadSchema)
    return validator(state)
}

export const createLead = (state: CheckoutState) => {
    if (isValidLead(state)) {
        return fetch(`${API_BASE_URL}/${CONTRACTS_ENDPOINT}/pub/v1/lead/create`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            mode: 'cors',
            body: JSON.stringify(state),
        })
    }

    return undefined
}

export const getLead = async (leadToken: string): Promise<LeadDataType | null> => {
    try {
        const response = await axios.get(
            `${PANDORA_API_BASE_URL}/leads-public/v1/lead/${leadToken}`
        )
        return response.data
    } catch (error) {
        return null
    }
}
