import Cookies from 'js-cookie'
import { BillingType, CookieDataType } from 'types/formData'
import { getPolicyStartDate } from 'utils/getPolicyStartDate'

const getBillingType = (cookieData: CookieDataType) => {
    if (cookieData.billing?.toLowerCase() === 'annual') {
        return BillingType.ANNUAL
    }

    if (cookieData.billing?.toLowerCase() === 'monthly') {
        return BillingType.MONTHLY
    }

    return undefined
}

export const isNatural = (value: any): boolean => {
    return (
        !Number.isNaN(parseFloat(value)) &&
        Number.isInteger(parseFloat(value)) &&
        parseFloat(value) >= 0
    )
}

const validate = (cookieData: CookieDataType) => {
    // Validate integer values
    if (cookieData.contribution && !isNatural(cookieData.contribution)) {
        throw new Error('Invalid contribution, expected number')
    }

    if (cookieData.pricebookId && !isNatural(cookieData.pricebookId)) {
        throw new Error('Invalid pricebookId, expected number')
    }

    if (cookieData.packageId && !isNatural(cookieData.packageId)) {
        throw new Error('Invalid packageId, expected number')
    }
}

export const loadFromCookie = () => {
    const cookieData: CookieDataType = JSON.parse(Cookies.get('incoming_data') || '{}')

    validate(cookieData)
    const billingType = getBillingType(cookieData)

    return {
        ...(cookieData.forename && { firstName: cookieData.forename }),
        ...(cookieData.surname && { lastName: cookieData.surname }),
        ...(cookieData.email_address && { email: cookieData.email_address }),
        ...(cookieData.contact_tel && { phone: cookieData.contact_tel }),
        ...(cookieData.street && { street: cookieData.street }),
        ...(cookieData.town && { town: cookieData.town }),
        ...(cookieData.county && { county: cookieData.county }),
        ...(cookieData.postcode && { postcode: cookieData.postcode }),
        ...(cookieData.date && { date: getPolicyStartDate(cookieData.date) }),
        ...(cookieData.contribution && {
            contribution: parseInt(cookieData.contribution, 10),
        }),
        ...(billingType && { billingType }),
        ...(cookieData.pricebookId && { pricebookId: parseInt(cookieData.pricebookId, 10) }),
        ...(cookieData.packageId && { packageId: parseInt(cookieData.packageId, 10) }),
        ...(cookieData.packageType && { packageType: cookieData.packageType }),
        ...(cookieData.qs && { querystring: cookieData.qs }),
        ...(cookieData.source && { source: cookieData.source }),
        ...(cookieData.referer && { httpReferrer: cookieData.referer }),
        ...(cookieData.landing && { landingPage: cookieData.landing }),
        ...(cookieData.gaclientid && { gaClientId: cookieData.gaclientid }),
        ...(cookieData.quote_id && { partnerQuoteId: cookieData.quote_id }),
        ...(cookieData.productName && { productName: cookieData.productName }),
        ...(cookieData.monthlyTotalPrice && { monthlyTotalPrice: cookieData.monthlyTotalPrice }),
        ...(cookieData.monthlyDiscount && { monthlyDiscount: cookieData.monthlyDiscount }),
        ...(cookieData.transactionId && { transactionId: cookieData.transactionId }),
        ...(cookieData.transactionId && { transactionId: cookieData.transactionId }),
        ...(cookieData.goCardless && { goCardless: cookieData.goCardless }),
        ...(cookieData.nextServiceDate && { nextServiceDate: cookieData.nextServiceDate }),
        ...(cookieData.referralLink && { referralLink: cookieData.referralLink }),
        ...(cookieData.referralCode && { referralCode: cookieData.referralCode }),
        ...(cookieData.referralCodeURL && { referralCodeURL: cookieData.referralCodeURL }),
        ...(cookieData.promoCode && { promoCode: cookieData.promoCode }),
        ...(typeof cookieData.marketing !== 'undefined' && {
            marketingEmail: cookieData.marketing,
            marketingPhone: cookieData.marketing,
            marketingSms: cookieData.marketing,
        }),
        ...(typeof cookieData.marketingEmail !== 'undefined' && {
            marketingEmail: cookieData.marketingEmail,
        }),
        ...(typeof cookieData.marketingPhone !== 'undefined' && {
            marketingPhone: cookieData.marketingPhone,
        }),
        ...(typeof cookieData.marketingSms !== 'undefined' && {
            marketingSms: cookieData.marketingSms,
        }),
        ...(typeof cookieData.referralProgramme !== 'undefined' && {
            referralProgramme: cookieData.referralProgramme,
        }),
        ...(typeof cookieData.registerCustomerRequest !== 'undefined' && {
            registerCustomerRequest: cookieData.registerCustomerRequest,
        }),
        ...(typeof cookieData.salesAgent !== 'undefined' && {
            salesAgent: cookieData.salesAgent,
        }),
        ...(typeof cookieData.addons !== 'undefined' && {
            addons: cookieData.addons,
        }),
    }
}

export const loadMarketingHashFromCookie = () => {
    const cookieData = JSON.parse(Cookies.get('marketing_preferences') || '{}')

    return {
        customerPrefsHash: cookieData.type,
    }
}
