import { CheckoutState } from 'contexts/checkout/type'
import { dateToString } from 'utils/dateToString'

export const mapRegisterCustomerPayload = (state: CheckoutState) => ({
    author: state.author,
    first_name: state.firstName,
    last_name: state.lastName,
    email: state.email?.replace(/\s/g, ''),
    phone1_prefix: state.phonePrefix,
    phone1: state.phone,
    marketing_email: state.marketingEmail,
    marketing_phone: state.marketingPhone,
    marketing_sms: state.marketingSms,
    address_line_1: state.street,
    postcode: state.postcode,
    town: state.town,
    country: state.country,
    start_date: state.date ? dateToString(state.date) : undefined,
    package_id: state.packageId,
    package_type: state.packageType,
    pricebook_id: state.pricebookId,
    payment_amount: Math.round(state.monthlyTotalPrice - state.monthlyDiscount),
    contribution: state.contribution * 100, // TODO standardise money amounts across the app to be pence
    billing_type: state.billingType,
    gc_success_redirect: state.gcSuccessRedirect,
    correspondence: {
        postcode: state.postcode,
        address_line_1: state.street,
        town: state.town,
        country: state.country,
    },
    ga_cid: state.gaClientId,
    http_referer: state.httpReferrer,
    landingpage: state.landingPage,
    querystring: state.querystring,
    referral_programme: state.referralProgramme,
    partner_quote_id: state.partnerQuoteId,
    transaction_id: state.transactionId,
    ...(state.salesAgent !== '' && { sales_agent: state.salesAgent }),
    ...(typeof state.goCardless !== 'undefined' && {
        go_cardless: {
            billing_request_id: state.goCardless?.billing_request_id,
            billing_request_flow_id: state.goCardless?.billing_request_flow_id,
            creditor_id: state.goCardless?.creditor_id,
            customer_id: state.goCardless?.customer_id,
            customer_bank_account_id: state.goCardless?.customer_bank_account_id,
            customer_billing_detail_id: state.goCardless?.customer_billing_detail_id,
            mandate_request_id: state.goCardless?.mandate_request_id,
            mandate_request_mandate_id: state.goCardless?.mandate_request_mandate_id,
        },
    }),
    ...(typeof state.chargebee !== 'undefined' && {
        chargebee: {
            payment_intent_id: state.chargebee?.payment_intent_id,
        },
    }),
    ...((state.referralCode || state.referralCodeURL) && {
        // Referral codes start with REF but we don't send that to the backend
        // Use referral code from URL params if customer has not inputted it
        referral_code:
            (state.referralCode && state.referralCode.substring(3)) ||
            (state.referralCodeURL && state.referralCodeURL.substring(3)),
    }),
    ...(state.promoCode && {
        promocode: state.promoCode.code,
    }),
    ...(state.addons && {
        addons: state.addons
            .filter((addon) => addon.selected)
            .map((addon) => ({
                id: addon.id,
                quantity: 1,
            })),
    }),
    version: '1.1.0',
})
