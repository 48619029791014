import { Addon, Package } from 'types/catalogue.v2'
import { BillingType } from '../types/formData'

export const calculateMonthlyPackagePrice = (contribution: number, pkg: Package): number => {
    return pkg.prices.find((price) => price.contribution === contribution)?.price || 0
}

export const calculateTotalMonthlyPrice = (
    packagePrice: number,
    threeYearFixedPrice?: number
): number => {
    return threeYearFixedPrice ? packagePrice + threeYearFixedPrice : packagePrice
}

export const get3YearFixedPrice = (contribution: number, addon?: Addon): number | undefined => {
    return addon?.prices.find((price) => price.contribution === contribution)?.price
}

export const toBillingPrice = (amount: number, billingOption: string): number =>
    billingOption.toLowerCase() === BillingType.ANNUAL ? amount * 12 : amount
