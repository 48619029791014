import Checkbox from 'components/checkbox'
import { useCheckoutContext } from 'contexts/checkout'

type Props = {
    termsCheckbox: boolean
    setTermsCheckbox: React.Dispatch<React.SetStateAction<boolean>>
    openModal: boolean
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}
export function TermsAndConditions({
    termsCheckbox,
    setTermsCheckbox,
    openModal,
    setOpenModal,
}: Props) {
    const {
        state: { packageName, packageDetails },
    } = useCheckoutContext()

    const bulletPoints = (
        <ul>
            <li>I have a boiler connected to mains gas</li>
            <li>
                My boiler and central heating system are in&nbsp;
                {/* eslint-disable-next-line */}
                <a
                    onClick={(e) => {
                        e.preventDefault()
                        setOpenModal(!openModal)
                    }}
                    href=""
                >
                    good working order
                </a>
            </li>
            <li>
                I have read and accept&nbsp;
                <a
                    title="Terms and Conditions"
                    target="_blank"
                    href={
                        packageDetails?.is_insurance
                            ? 'https://www.hometree.co.uk/terms-and-conditions/'
                            : 'https://www.hometree.co.uk/maintenance-terms-and-conditions/'
                    }
                    rel="noreferrer"
                >
                    Hometree&apos;s terms and conditions
                </a>
            </li>
        </ul>
    )

    const heatPumpCoverBulletPoints = (
        <ul>
            <li>My heat pump has been installed within the last 12 months</li>
            <li>
                I have read and accept&nbsp;
                <a
                    title="Terms and Conditions"
                    target="_blank"
                    href="https://www.hometree.co.uk/wp-content/uploads/2024/04/Terms-and-Conditions-Heat-Pump-Servicing-Plan-Updated-12-April-2024.pdf"
                    rel="noreferrer"
                >
                    Hometree&apos;s terms and conditions
                </a>
            </li>
        </ul>
    )

    return (
        <Checkbox
            id="HomeCareCover_Checkout_Payment_ChangeAgreeTerms"
            onChange={(e) => setTermsCheckbox(e.target.checked)}
            checked={termsCheckbox}
        >
            <>
                <p>By purchasing this product I confirm:</p>
                {(packageName || '').includes('Heat Pump')
                    ? heatPumpCoverBulletPoints
                    : bulletPoints}
            </>
        </Checkbox>
    )
}
