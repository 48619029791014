import axios from 'axios'
import { CatalogueResponse } from 'types/catalogue.v2'

export const getCataloguePrices = async (pricebookId: number) => {
    try {
        const res = await axios.get<CatalogueResponse>(
            `${process.env.REACT_APP_PANDORA_API_URL}/pricebooks/model/pricebooks/catalogue/v2/${pricebookId}`
        )
        return res.data
    } catch (error) {
        return null
    }
}
