import * as Sentry from '@sentry/react'
import { getCataloguePrices } from 'api/catalogue'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
import { ReactComponent as PartnersVoucher } from 'assets/icons/partners-voucher.svg'
import { ReactComponent as Strike } from 'assets/strike.svg'
import { ReactComponent as TickLogo } from 'assets/tick.svg'
import Checkbox from 'components/checkbox'
import { useCheckoutContext } from 'contexts/checkout'
import { CheckoutState } from 'contexts/checkout/type'
import { sendPageLandAnalytics } from 'features/analytics'
import { PromoContext } from 'features/promoMode'
import React, { useContext, useEffect, useState } from 'react'
import { Package, Product } from 'types/catalogue.v2'
import { BillingType } from 'types/formData'
import {
    calculateMonthlyPackagePrice,
    calculateTotalMonthlyPrice,
    get3YearFixedPrice,
    toBillingPrice,
} from 'utils/checkoutPrice'
import formatName from 'utils/formatName'
import formatProductNames from 'utils/formatProductNames'
import { penceToPounds } from 'utils/penceToPounds'
import { isMSMVisitor } from '../../../utils/partners'
import styles from './index.module.scss'

function OrderForm() {
    const {
        state: {
            contribution,
            billingType: billing,
            packageId,
            pricebookId,
            monthlyTotalPrice,
            monthlyDiscount,
            source,
            addons,
            querystring,
        },
        dispatch,
    } = useCheckoutContext()

    // All prices should be in pence
    const [selectedPackage, setSelectedPackage] = useState<Package | null>(null)
    const [threeYearFixedAddonPrice, setThreeYearFixedAddonPrice] = useState<number | undefined>(
        undefined
    )
    const [strikethroughPrice, setStrikethroughPrice] = useState<number | undefined>(undefined)
    const [threeYearOptedInInitial, setThreeYearOptedInInitial] = useState<boolean | undefined>(
        undefined
    )
    const [threeYearOptedIn, setThreeYearOptedIn] = useState<boolean | undefined>(undefined)

    const { phase: promoPhase } = useContext(PromoContext)
    const promoPeriodActive = !!promoPhase

    const isInsurancePackage = selectedPackage?.is_insurance
    const isCorePackage = selectedPackage?.products?.some(
        (product: Product) => product.product_id === 1
    )

    const mapPackageType = {
        Homeowner: 'homeowner',
        Landlord: 'landlord',
    }

    const renderInsuranceTaxText = () =>
        isCorePackage
            ? `This price includes Insurance Premium Tax and one annual service visit at £72 incl. VAT`
            : `This price includes Insurance Premium Tax`

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let mounted = true

        const getCatalogue = async () => {
            try {
                if (!packageId || !pricebookId) {
                    return
                }

                const response = await getCataloguePrices(pricebookId)
                if (!response || !response.data) {
                    return
                }

                const selectedPackageEntity =
                    response.data.items[0].pricebook_entries.packages.find(
                        (pkg) => pkg.package_id === packageId
                    )
                if (!selectedPackageEntity) {
                    return
                }

                const packagePrice = calculateMonthlyPackagePrice(
                    contribution,
                    selectedPackageEntity
                )

                const catalogue3YearFixedAddon =
                    response.data.items[0].pricebook_entries.addons.find(
                        (addon) => addon.name === '3 Year Fixed Price'
                    )

                const state3YearFixedAddon = addons?.find(
                    (addon) => addon.name === '3 Year Fixed Price'
                )

                setThreeYearOptedInInitial(state3YearFixedAddon?.selected)
                setThreeYearOptedIn(state3YearFixedAddon?.selected)

                if (state3YearFixedAddon && catalogue3YearFixedAddon) {
                    state3YearFixedAddon.prices = catalogue3YearFixedAddon?.prices
                    state3YearFixedAddon.id = catalogue3YearFixedAddon?.product_id
                }

                const calculated3YearFixedAddonPrice = get3YearFixedPrice(
                    contribution,
                    catalogue3YearFixedAddon
                )

                const newTotalPrice = calculateTotalMonthlyPrice(
                    packagePrice,
                    state3YearFixedAddon?.selected ? calculated3YearFixedAddonPrice : undefined
                )

                const packageName = formatName(selectedPackageEntity.name || 'Not found')

                setSelectedPackage(selectedPackageEntity)
                setThreeYearFixedAddonPrice(calculated3YearFixedAddonPrice)
                dispatch({
                    type: 'setCheckout',
                    data: {
                        packageType: mapPackageType[selectedPackageEntity.type] || 'homeowner',
                        monthlyTotalPrice: newTotalPrice || 0,
                        packageName,
                        packageDetails: selectedPackageEntity,
                        ...(state3YearFixedAddon && { addons: [state3YearFixedAddon] }),
                    },
                })

                sendPageLandAnalytics('Checkout', 'PaymentPage', packageName.replace(/\s/g, ''))

                // TODO not very DRY
                if (promoPeriodActive && promoPhase?.pricebooks.fullPriceBook) {
                    const fullPriceResponse = await getCataloguePrices(
                        promoPhase.pricebooks.fullPriceBook
                    )
                    if (!fullPriceResponse || !fullPriceResponse.data) {
                        return
                    }

                    const fullPriceSelectedPackageEntity =
                        fullPriceResponse.data.items[0].pricebook_entries.packages.find(
                            (pkg) => pkg.package_id === packageId
                        )
                    if (!fullPriceSelectedPackageEntity) {
                        return
                    }

                    setStrikethroughPrice(
                        calculateMonthlyPackagePrice(contribution, fullPriceSelectedPackageEntity)
                    )
                }
            } catch (err) {
                Sentry.captureException(err)
            }
        }

        if (packageId && pricebookId) {
            getCatalogue()
        }

        return () => {
            mounted = false
        }
    }, [packageId, pricebookId])

    const on3YearFixCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target
        const data: Partial<CheckoutState> = {}

        const threeYearFixedAddon = addons?.find((addon) => addon.name === '3 Year Fixed Price')

        const priceObj = selectedPackage?.prices.find(
            (price) => price.contribution === contribution
        )

        if (threeYearFixedAddonPrice && threeYearFixedAddon && priceObj) {
            threeYearFixedAddon.selected = checked
            data.monthlyTotalPrice = priceObj.price + (checked ? threeYearFixedAddonPrice : 0)
            data.addons = [threeYearFixedAddon]

            if (strikethroughPrice) {
                setStrikethroughPrice(
                    strikethroughPrice +
                        (checked ? threeYearFixedAddonPrice : -threeYearFixedAddonPrice)
                )
            }

            dispatch({
                type: 'setCheckout',
                data,
            })
            setThreeYearOptedIn(checked)
        }
    }

    const packageName = formatName(selectedPackage?.name || '')

    const productItems = formatProductNames(selectedPackage?.products || [])
        .filter(({ featured, included }) => featured && included)
        .map(({ product_id, name }: Product) => (
            <li key={product_id}>
                <TickLogo />
                <h3>{name}</h3>
            </li>
        ))

    const getProductListClass = () => {
        switch (productItems.length) {
            case 2:
            case 3:
            case 4:
            case 5:
                return styles.productListCol
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            default:
                return styles.productList2Cols
        }
    }

    let voucherAmount = 0

    if (packageId) {
        switch (packageId) {
            case 13:
                voucherAmount = 20
                break
            case 14:
                voucherAmount = 30
                break
            case 15:
            case 16:
                voucherAmount = 40
                break
            default:
                voucherAmount = 0
        }
    }

    return (
        <form className={styles.orderForm}>
            <div>
                <h1>Your Order</h1>
                <p>
                    This plan meets the needs of a customer who wants to cover the items ticked
                    below.
                </p>

                <hr />

                <h2>{packageName}</h2>
                <div className={styles.productList}>
                    <ul className={getProductListClass()}>{productItems}</ul>
                </div>

                <hr />
                <div className={styles.orderAdjustments}>
                    {isInsurancePackage && threeYearFixedAddonPrice && threeYearOptedInInitial && (
                        <div className={styles.addons}>
                            <span>
                                <span className={styles.threeYearFixed}>
                                    <h4 className={styles.threeYearFixedTitle}>
                                        Price fixed for 3 years{' '}
                                    </h4>
                                    <span
                                        className={`${styles.threeYearFixedPopover} ${styles.popover}`}
                                    >
                                        <span>
                                            <InfoIcon />{' '}
                                        </span>
                                        <div
                                            className={`${styles.threeYearFixedPopoverContent} ${styles.popoverContent}`}
                                        >
                                            <span className={styles.threeYearFixedContent}>
                                                <h4 className={styles.threeYearFixedContentTitle}>
                                                    Price fixed for 3 years - overview
                                                </h4>
                                                <p>
                                                    <ul>
                                                        <li>Contract will renew every 12 months</li>
                                                        <li>
                                                            Your price will not change for 36 month
                                                            from your start date
                                                        </li>
                                                        <li>
                                                            After 36 months your plan will renew but
                                                            the price will no longer be fixed
                                                        </li>
                                                    </ul>
                                                </p>
                                            </span>
                                        </div>
                                    </span>
                                </span>
                            </span>
                            <Checkbox
                                id="checkout_pg_3yfp"
                                checked={
                                    addons?.find((addon) => addon.name === '3 Year Fixed Price')
                                        ?.selected
                                }
                                ariaLabel="Price fix for 3 years addon"
                                onChange={on3YearFixCheckboxClick}
                            >
                                {' '}
                            </Checkbox>
                        </div>
                    )}
                    <div className={styles.contribution}>
                        <span>
                            <span className={styles.callout}>
                                <h4>Call-out fee </h4>
                                <span className={`${styles.calloutPopover} ${styles.popover}`}>
                                    <span>
                                        <InfoIcon />{' '}
                                    </span>
                                    <div
                                        className={`${styles.calloutPopoverContent} ${styles.popoverContent}`}
                                    >
                                        <p>
                                            This is the excess which you pay under the insurance
                                            policy. You pay this once per fault. The higher the
                                            call-out fee you select, the lower the monthly or annual
                                            price you pay. We do not charge call-out fees for your
                                            service visit. You can change your call-out fee by
                                            returning to the previous page.
                                        </p>
                                    </div>
                                </span>
                            </span>
                            <h4>(only charged at call-out)</h4>
                        </span>
                        <h3>&pound;{contribution?.toFixed(2)}</h3>
                    </div>
                </div>

                <div className={styles.totalPrice}>
                    {packageId &&
                        isMSMVisitor(source || '', querystring || '') &&
                        voucherAmount > 0 && (
                            <div className={styles.voucher}>
                                <PartnersVoucher className={styles.voucherIcon} />
                                <span className={styles.voucherText}>
                                    £{voucherAmount} voucher included
                                </span>
                            </div>
                        )}

                    <div>
                        <h3>
                            Total price
                            <br />
                            {`(paid ${
                                billing === BillingType.ANNUAL ? 'annually' : BillingType.MONTHLY
                            })`}
                        </h3>
                        <span className={styles.priceValues}>
                            {promoPeriodActive &&
                            promoPhase?.checkout.strikethrough &&
                            !packageName?.includes('Essentials') &&
                            strikethroughPrice !== monthlyTotalPrice ? (
                                <span className={styles.ogPriceWrapper}>
                                    <Strike />
                                    <p className={styles.originalPrice}>
                                        &pound;
                                        {penceToPounds(
                                            Math.round(
                                                toBillingPrice(
                                                    (strikethroughPrice || 0) - monthlyDiscount ||
                                                        0,
                                                    billing || ''
                                                )
                                            )
                                        )}
                                    </p>
                                </span>
                            ) : (
                                <span className={styles.spacer} />
                            )}
                            <h2>
                                &pound;
                                {penceToPounds(
                                    Math.round(
                                        toBillingPrice(
                                            monthlyTotalPrice - monthlyDiscount || 0,
                                            billing || ''
                                        )
                                    )
                                )}
                            </h2>
                        </span>
                    </div>
                    <p>
                        {threeYearOptedIn && threeYearFixedAddonPrice && (
                            <span>
                                An additional £5 has been added to the monthly cost cover the{' '}
                                <b>Price fixed 3 year plan</b>.{' '}
                            </span>
                        )}
                        {isInsurancePackage && <span>{renderInsuranceTaxText()}</span>}
                    </p>
                </div>
            </div>
        </form>
    )
}

export default OrderForm
